export const APP_BASE_URL = '/shared-listings';
export const COMPASS_BASE_URL = 'https://www.compass.com';

// Analytics
export const SOURCE_APP = 'shared_listings_landing_page';
export const CONTACT_TYPE = {EMAIL: 'email', PHONE: 'phone'};

export const DEFAULT_SHARE_THUMBNAIL_URL =
  // TODO upload this to telescope and remove dependency to contentful
  // eslint-disable-next-line
  'https://www.compass.com/ucfe-assets/public-media/ogImage-listingPage-missingPhotography.jpg';
