import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import formatListingUrl from '@uc/format-listing-url';
import ListingPhotoCard from '@uc/listing-photocard.react';

import {connect} from '@context';
import {fetchOptyState, FEATURE_ONLY} from '@uc/opty/client';
import listingsActions from '@context/actions/listings';

import Loader from '@components/shared/Loader';

import {SOURCE_APP} from '@constants';
import {decodeListingIds, getUrlParamByKey} from '@utils';
import {useState} from 'react';

const ListingCards = ({
  listings,
  isFetching,
  error,
  getListings,
  agentId,
  location,
  displayCarousel,
}) => {
  const [isSubstatsPoptipEnabled, setIsSubstatsPoptipEnableduseState] = useState(false);
  useEffect(() => {
    if (location && location.search) {
      let listingIds = getUrlParamByKey(location.search, 'lid', {isList: true});
      if (getUrlParamByKey(location.search, 'encoded') === 'true') {
        listingIds = decodeListingIds(listingIds);
      }
      if (listingIds && listingIds.length > 0) {
        getListings(listingIds);
      }
      fetchOptyState({quarter_and_three_quarter_bathrooms_on_listing_pages: FEATURE_ONLY})
        .then(({features}) => setIsSubstatsPoptipEnableduseState(
          features.quarter_and_three_quarter_bathrooms_on_listing_pages,
        )).catch(() => {
          setIsSubstatsPoptipEnableduseState(false);
        });
    }
  }, []);

  if (isFetching) {
    return <Loader size="xl" />;
  }

  if (error) {
    return 'Encountered an error when retrieving listings';
  }

  if (!listings || listings.length === 0) {
    return 'No listings found.';
  }

  return (
    <div className="uc-sl-listing-cards-container">
      {listings.map((listing, idx) => {
        const onListingClick = () => {
          window.open(
            formatListingUrl(listing, {
              agent_id: agentId,
              origin: SOURCE_APP,
              origin_type: 'Listing Photocard',
            }),
          );
        };

        return (
          <ListingPhotoCard
            data={listing}
            displayCarousel={displayCarousel}
            isLoggedIn={false}
            lazyLoadSingleImage
            key={idx}
            listingAgentOverride={agentId}
            onListingClick={onListingClick}
            isSubstatsPoptipEnabled={isSubstatsPoptipEnabled}
          />
        );
      })}
    </div>
  );
};

ListingCards.propTypes = {
  agentId: PropTypes.string,
  error: PropTypes.bool,
  getListings: PropTypes.func,
  isFetching: PropTypes.bool,
  listings: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object,
  displayCarousel: PropTypes.bool,
};

ListingCards.defaultProps = {
  displayCarousel: true,
};

const mapStateToProps = (state) => {
  if (!state || !state.listings) {
    return {};
  }
  const {listings, isFetching, error} = state.listings;
  return {
    listings: Object.values(listings),
    isFetching,
    error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getListings: (listingIds) => {
    dispatch(listingsActions.getListings(listingIds));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListingCards);
