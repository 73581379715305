import {decode} from '@uc/base-encoder';

import {COMPASS_BASE_URL} from '@constants';

// Accepts a list of listing ids as a comma seperated string or as an array
export const decodeListingIds = (listingIds) => {
  const lids = typeof listingIds === 'string' ? listingIds.split(',') : listingIds;
  return lids.map((lid) => decode(lid));
};

export const getTimeAgoString = (timeInSeconds) => {
  const currentTime = new Date();
  const shareTime = new Date(timeInSeconds * 1000);

  if (!timeInSeconds || currentTime < shareTime) {
    return null;
  }

  const timeDiffMinutes = Math.round((currentTime - shareTime) / 1000 / 60);
  if (timeDiffMinutes < 60) {
    return timeDiffMinutes <= 1 ? '1 minute ago' : `${timeDiffMinutes} minutes ago`;
  }

  const timeDiffHours = Math.round(timeDiffMinutes / 60);
  if (timeDiffHours < 24) {
    return timeDiffHours <= 1 ? '1 hour ago' : `${timeDiffHours} hours ago`;
  }

  const timeDiffDays = Math.round(timeDiffHours / 24);
  if (timeDiffDays < 30) {
    return timeDiffDays <= 1 ? '1 day ago' : `${timeDiffDays} days ago`;
  }

  if (timeDiffDays < 365) {
    const timeDiffMonths = timeDiffDays > 360 ? 11 : Math.floor(timeDiffDays / 30);
    return timeDiffMonths <= 1 ? '1 month ago' : `${timeDiffMonths} months ago`;
  }

  const timeDiffYears = Math.floor(timeDiffDays / 365);
  return timeDiffYears <= 1 ? '1 year ago' : `${timeDiffYears} years ago`;
};

// Retrieves the value of a URL query param given the query string and param key
// Option args:
//   isList - boolean, parses parameters into a list. Default: false
//   delimiter - string, delimiter between list items. Default: ',' (comma)
export const getUrlParamByKey = (urlParams, key, options = {}) => {
  if (urlParams === '' || key === '') {
    return null;
  }

  let startIndex = urlParams.indexOf(`${key}=`);
  if (startIndex === -1) {
    return null;
  }
  startIndex = startIndex + key.length + 1; // + 1 for '='
  let params = urlParams.substring(startIndex);
  if (params.indexOf('&') > -1) {
    params = params.substring(0, params.indexOf('&'));
  }

  if (options.isList) {
    const delimiter = options.delimiter && options.delimiter.length > 0 ? options.delimiter : ',';
    return params.split(delimiter).filter((p) => p !== '');
  }

  return params;
};

export const getProfileUrl = (profile) => {
  if (
    !profile ||
    !profile.agent ||
    !profile.agent.safeUrlName ||
    !profile.agent.profilePages ||
    !profile.agent.profilePages.length > 0 ||
    !profile.agent.profilePages[0].geography
  ) {
    return `${COMPASS_BASE_URL}/agents/`;
  }

  const geography = profile.agent.profilePages[0].geography;
  return `https://www.compass.com/agents/${geography}/${profile.agent.safeUrlName}`;
};
