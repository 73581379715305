import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import {APP_BASE_URL} from './constants';
import {StateProvider, MobileProvider} from './context';
import LandingPage from './components/LandingPage';

const App = () => (
  <StateProvider>
    <MobileProvider>
      <BrowserRouter>
        <Switch>
          <Route
            render={({location, match}) => <LandingPage location={location} match={match} />}
            path={`${APP_BASE_URL}/:agentId`}
          />
          <Route component={LandingPage} />
        </Switch>
      </BrowserRouter>
    </MobileProvider>
  </StateProvider>
);

async function initializeApp() {
  try {
    // the @uc/corp-nav performs vanilla JS DOM manipulations which need to execute
    // before the ReactDOM is initialized to prevent the two DOMs from becoming out sync.
    // @ts-ignore
    await window.renderNav();
  } catch (err) {
    console.error('RenderNav Error:', err);
  }

  ReactDOM.render(<App />, document.getElementById('root'));
}

document.addEventListener('DOMContentLoaded', () => {
  initializeApp();
});
