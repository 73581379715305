import PropTypes from 'prop-types';
import React from 'react';

const Loader = ({size}) =>
  <div className={`cx-animDotShift ${size ? `cx-animDotShift--${size}` : ''}`} />
;
Loader.propTypes = {
  size: PropTypes.oneOf(['', 'xs', 'sm', 'md', 'lg', 'xl']),
};

Loader.defaultProps = {
  size: '',
};

export default Loader;
