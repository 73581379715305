import {SOURCE_APP} from '@constants';

const track = (eventName, agentId, extraProperties = {}) => {
  const properties = {
    product: SOURCE_APP,
    agent_id: agentId,
    ...extraProperties,
  };

  if (window.analytics) {
    window.analytics.track(eventName, properties);
  }
};

export const landingPageViewed = (agentId, listingIds) => {
  track('shared_listings_landing_page_viewed', agentId, {
    listing_id: listingIds && listingIds.length === 1 ? listingIds[0] : listingIds,
    listing_count: listingIds && listingIds.length || 0,
  });
};

export const agentProfileClicked = (agentId) => {
  track('agent_profile_clicked', agentId);
};

export const agentContactClicked = (agentId, contactType) => {
  // contact types can be found in the CONTACT_TYPE constant.
  track('agent_contact_clicked', agentId, {
    contact_type: contactType,
  });
};
