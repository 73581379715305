import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';
import React, {createContext, PureComponent} from 'react';

import {mq} from '@uc/cx-design-tokens';

const getIsMobile = () => window.innerWidth <= mq.tablet;

const {Provider, Consumer} = createContext(getIsMobile());

class MobileProvider extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {isMobile: getIsMobile()};
    // Throttles resize handler to every 200ms to prevent performace issues
    this.throttledResizeListener = throttle(this.resizeListener, 200);
  }

  resizeListener = () => {
    this.setState({isMobile: getIsMobile()});
  };

  componentDidMount() {
    window.addEventListener('resize', this.throttledResizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResizeListener);
  }

  render() {
    return <Provider value={this.state.isMobile}>{this.props.children}</Provider>;
  }
}

MobileProvider.propTypes = {
  children: PropTypes.any,
};

// Component Wrappers
export const Mobile = ({children}) =>
  <Consumer>{(isMobile) => isMobile ? children : null}</Consumer>
;

Mobile.propTypes = {
  children: PropTypes.any,
};

export const Desktop = ({children}) =>
  <Consumer>{(isMobile) => !isMobile ? children : null}</Consumer>
;
Desktop.propTypes = {
  children: PropTypes.any,
};

// Functions that wraps a component with <Mobile> or <Desktop>
export const mobileOnly = (Component) => (props) => (
  <Mobile>
    <Component {...props} />
  </Mobile>
);

export const desktopOnly = (Component) => (props) => (
  <Desktop>
    <Component {...props} />
  </Desktop>
);

// Provides an isMobile prop to the component.
export const withMobileProp = (Component) => (props) =>
  <Consumer>{(isMobile) => <Component {...props} isMobile={isMobile} />}</Consumer>
;

export default MobileProvider;
