import React from 'react';
import PropTypes from 'prop-types';
import Loader from '@components/shared/Loader';

const EmptyState = ({isFetching, error, hasListings}) => {
  if (isFetching) {
    return <Loader size="xl" />;
  }

  if (error) {
    return 'Encountered an error when retrieving listings';
  }

  if (!hasListings) {
    return 'No listings found.';
  }

  return null;
};

EmptyState.propTypes = {
  isFetching: PropTypes.bool,
  error: PropTypes.bool,
  hasListings: PropTypes.bool,
};

EmptyState.defaultProps = {
  isFetching: false,
  error: false,
  hasListings: false,
};

export default EmptyState;
