import EventEmitter from 'events';

export const ToastVariant = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

export class ActionEmitter extends EventEmitter {
  static Event = {
    SHOW_TOAST: 'SHOW_TOAST',
  };

  showToast = (message, options = {}) => {
    const toastEvent = {
      message,
      variant: options.variant || ToastVariant.INFO,
      duration: options.duration || 6000,
      action: options.action,
      onActionClick: options.onActionClick,
    };

    this.emit(ActionEmitter.Event.SHOW_TOAST, toastEvent);
  };
}

export const actionEmitter = new ActionEmitter();
