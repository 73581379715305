import React, {useState, useEffect} from 'react';
import {getCollectionsForListings} from '@uc/thrift2npme/dist/collections/queries/collections_for_listings_service.ucfetch';
import {addListing} from '@uc/thrift2npme/dist/collections_default/collections_default_service.ucfetch';
import {trashListingsCommand as removeListingsFromCollection} from '@uc/thrift2npme/dist/collections/commands/listings_commands_service.ucfetch';
import {loadCurrentModal} from '@uc/modal-loader';
import {useToast} from '@context/toastContext';

export const useCollections = (listings = []) => {
  const {showSuccessToast, showErrorToast} = useToast();

  const [collectionsInProgress, setCollectionsInProgress] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [listingsInCollection, setListingsInCollection] = useState({});

  const fetchUserCollections = async () => {
    if (!listings || listings.length === 0) {
      return;
    }

    try {
      const listingIds = listings
        .map((listing) => listing.listingIdSHA)
        .filter(Boolean);
      const {
        privateCollections = [],
        publicCollections = [],
      } = await getCollectionsForListings({
        listingIdSHAs: listingIds,
        includePrivateCollections: true,
        includePublicCollections: true,
      });

      const inCollection = {};
      [...privateCollections, ...publicCollections].forEach((collection) => {
        collection.matchedListings?.forEach((listing) => {
          inCollection[listing.listingId] = true;
        });
      });

      setListingsInCollection(inCollection);
    } catch (error) {
      console.error('Error fetching collection status:', error);
    }
  };

  const initializeCollectionListings = (listings) => {
    return listings.map((listing) => ({
      listingIdSHA: listing.listingIdSHA || '',
      listing,
    }));
  };

  const checkAllListingsInCollection = ({listings, collection}) => {
    return listings.every((listing) => {
      return collection.matchedListings?.some(
        (matchedListing) => matchedListing.listingId === listing.listingIdSHA,
      );
    });
  };

  const checkBeforeOpenModal = async (listing) => {
    try {
      const {
        privateCollections = [],
        publicCollections = [],
      } = await getCollectionsForListings({
        listingIdSHAs: [listing.listingIdSHA],
        includePrivateCollections: true,
        includePublicCollections: true,
      });

      const collectionListings = initializeCollectionListings([listing]);
      const initialSelectedCollections = [
        ...publicCollections,
        ...privateCollections,
      ].filter((collection) => {
        const isAllListingsInCollection = checkAllListingsInCollection({
          listings: collectionListings,
          collection,
        });
        return isAllListingsInCollection && collection;
      });

      if (initialSelectedCollections.length === 1) {
        setCollectionsInProgress((prev) => ({
          ...prev,
          [listing.listingIdSHA]: true,
        }));
        await removeListingsFromCollection(
          initialSelectedCollections[0].collectionId,
          {
            isMobileApp: false,
            collectionId: initialSelectedCollections[0].collectionId,
            listingsIds: [listing.listingIdSHA],
          },
        );
        setListingsInCollection((prev) => ({
          ...prev,
          [listing.listingIdSHA]: false,
        }));
        console.log('Listing removed from collection successfully');
        showSuccessToast(
          (
            <span>
              Listing removed from <strong>{initialSelectedCollections[0].name}</strong>{' '}
              successfully
            </span>
          ),
        );
      } else {
        openOneClickSaveModal(listing);
      }
    } catch (error) {
      console.error('Error checking collections:', error);
      showErrorToast('Error checking collections');
    } finally {
      setCollectionsInProgress((prev) => ({
        ...prev,
        [listing.listingIdSHA]: false,
      }));
    }
  };

  const openOneClickSaveModal = (listing) => {
    loadCurrentModal('modal--add-listings-to-collections.react', {
      isAgent: false,
      listings: [listing],
      isOneClickSaveEnabled: true,
    }).then(async () => {
      await fetchUserCollections();
    });
  };

  const handleAddToCollection = async (listing) => {
    try {
      setCollectionsInProgress((prev) => ({
        ...prev,
        [listing.listingIdSHA]: true,
      }));
      const {defaultCollection} =
        await addListing({
          listingIdSHA: listing.listingIdSHA,
        }) || {};
      setListingsInCollection((prev) => ({
        ...prev,
        [listing.listingIdSHA]: true,
      }));
      console.log('Listing added to collection successfully');
      if (defaultCollection) {
        showSuccessToast(
          (
            <span>
              Listing added to <strong>{defaultCollection.title}</strong> successfully
            </span>
          ),
          {
            action: 'Change',
            onActionClick: () => {
              openOneClickSaveModal(listing);
            },
          },
        );
      }
    } catch (error) {
      console.error('Error adding listing to collection:', error);
      showErrorToast('Error adding listing to collection');
      setListingsInCollection((prev) => ({
        ...prev,
        [listing.listingIdSHA]: false,
      }));
    } finally {
      setCollectionsInProgress((prev) => ({
        ...prev,
        [listing.listingIdSHA]: false,
      }));
    }
  };

  const handleOpenSelectDefaultModal = async (listing) => {
    if (listingsInCollection[listing.listingIdSHA]) {
      await checkBeforeOpenModal(listing);
    } else {
      await handleAddToCollection(listing);
    }
  };

  const handleLogin = (user) => {
    console.log('User logged in:', user);
    if (typeof window !== 'undefined') {
      window.uc.user = user;
    }
    setIsLoggedIn(true);
    fetchUserCollections();
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window?.uc?.user) {
      setIsLoggedIn(true);
      fetchUserCollections();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserCollections();
    }
  }, [listings, isLoggedIn]);

  return {
    collectionsInProgress,
    isLoggedIn,
    listingsInCollection,
    handleOpenSelectDefaultModal,
    handleLogin,
    handleAddToCollection,
    checkBeforeOpenModal,
  };
};
