import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import AgentHeader from '@uc/agent-header.react';

import {withMobileProp} from '@context';

import AgentInfo from '@components/AgentInfo';
import ListingCards from '@components/ListingCards';

import {getUrlParamByKey, decodeListingIds} from '@utils';
import {landingPageViewed} from '@utils/analytics';
import './styles.pcss';

const LandingPage = ({isMobile, location, match}) => {
  const agent = typeof window !== 'undefined' && window.uc ? window.uc.agent : null;
  const agentId = match && match.params && match.params.agentId;
  let listingIds = [];
  if (location && location.search) {
    listingIds = getUrlParamByKey(location.search, 'lid', {isList: true});
    if (getUrlParamByKey(location.search, 'encoded') === 'true') {
      listingIds = decodeListingIds(listingIds);
    }
  }

  const shareTime = location && location.search ? getUrlParamByKey(location.search, 'date') : null;

  useEffect(() => {
    landingPageViewed(agentId, listingIds);
  }, []);

  const agentForHeader = agent
    ? {
      id: agent.personId,
      firstName: agent.firstName,
      lastName: agent.lastName,
      email: agent.email,
      phone: agent.phone,
      avatarUrl: agent.avatarUrl,
    }
    : null;
  if (agent?.profile?.profilePages && agent.profile.profilePages.length > 0) {
    agentForHeader.title = agent.profile.profilePages[0].agent?.title;
  }

  return (
    <div className="uc-landing-page-container">
      <AgentHeader
        sourceApp="shared_listings"
        isMobile={isMobile}
        agent={agentForHeader}
        shareTime={shareTime}
        listingCount={listingIds && listingIds.length || 0}
      />
      <div className="uc-landing-page-content">
        <ListingCards location={location} agentId={agentId} />
        <AgentInfo />
      </div>
    </div>
  );
};

LandingPage.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
};

LandingPage.defaultProps = {
  location: {},
  match: {},
};

export default withMobileProp(LandingPage);
