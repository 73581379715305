import PropTypes from 'prop-types';
import React, {createContext, useReducer} from 'react';

import initialState from './initialState';
import {useThunkMiddleware} from './middleware';
import rootReducer from './reducers';

const StateContext = createContext(initialState);

export const StateProvider = ({children}) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const enhancedDispatch = useThunkMiddleware(dispatch, state);

  return (
    <StateContext.Provider value={{state, dispatch: enhancedDispatch}}>
      {children}
    </StateContext.Provider>
  );
};

StateProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

// Provides empty props for when a connected component (ie. connect(_, _)(Component))
// doesn't use mapStateToProps or mapDispatchToProps.
const withDefaultProps = (mapToPropsFunction) => (stateOrDispatch, props) =>
  mapToPropsFunction ? mapToPropsFunction(stateOrDispatch, props) : {};

// Connects a component to the global state provider.
export const connect = (mapStateToProps, mapDispatchToProps) => {
  return (Component) => {
    const ConnectedComponenet = (props = {}) => (
      <StateContext.Consumer>
        {({state, dispatch}) => (
          <Component
            {...props}
            {...withDefaultProps(mapStateToProps)(state, props)}
            {...withDefaultProps(mapDispatchToProps)(dispatch, props)}
          />
        )}
      </StateContext.Consumer>
    );
    return ConnectedComponenet;
  };
};

export default StateProvider;
