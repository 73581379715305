import {loadListings} from '@uc/thrift2npme/dist/listing_translation/listing_translation_service.ucfetch';
import {ClientPurpose} from '@uc/thrift2npme/dist/messier/messier_helpers';

const FETCH_LISTINGS = 'FETCH_LISTINGS';
const FETCH_LISTINGS_SUCCESS = 'FETCH_LISTINGS_SUCCESS';
const FETCH_LISTINGS_FAILURE = 'FETCH_LISTINGS_FAILURE';

export const actionTypes = {
  FETCH_LISTINGS,
  FETCH_LISTINGS_SUCCESS,
  FETCH_LISTINGS_FAILURE,
};

const shouldFetchListings = (state, listingIds) => {
  if (listingIds.length === 0 || state.listings.isFetching) {
    return false;
  }
  return !listingIds.every((listingId) => !!state.listings.listings[listingId]);
};

const fetchListings = () => ({type: FETCH_LISTINGS});

const fetchListingsSuccess = (listings) => ({type: FETCH_LISTINGS_SUCCESS, payload: {listings}});

const fetchListingsFailure = () => ({type: FETCH_LISTINGS_FAILURE});

const getListings = (listingIds) => (dispatch, state) => {
  if (shouldFetchListings(state, listingIds)) {
    dispatch(fetchListings());
    const uniqueListingIds = [...new Set(listingIds)];
    return loadListings({
      listingIdSHAs: uniqueListingIds,
      clientPurpose: ClientPurpose.LOAD_LISTING_FOR_DISPLAY,
    }).then(
      (response) => {
        dispatch(fetchListingsSuccess(response.listings));
      },
      () => {
        dispatch(fetchListingsFailure());
      },
    );
  }
};

export default {
  getListings,
  fetchListings,
  fetchListingsSuccess,
  fetchListingsFailure,
};
