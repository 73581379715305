import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {connect} from '@context';
import listingsActions from '@context/actions/listings';

import {useCollections} from '@hooks/useCollections';
import {useToast} from '@context/toastContext';
import {decodeListingIds, getUrlParamByKey} from '@utils';
import ListingCardItem from './ListingCardItem';
import EmptyState from './EmptyState';

const ListingCards = ({
  listings,
  isFetching,
  error,
  getListings,
  agentId,
  location,
  displayCarousel = true,
}) => {
  const {
    collectionsInProgress,
    isLoggedIn,
    listingsInCollection,
    handleOpenSelectDefaultModal,
    handleLogin,
  } = useCollections(listings);

  useEffect(() => {
    if (location && location.search) {
      let listingIds = getUrlParamByKey(location.search, 'lid', {isList: true});
      if (getUrlParamByKey(location.search, 'encoded') === 'true') {
        listingIds = decodeListingIds(listingIds);
      }
      if (listingIds && listingIds.length > 0) {
        getListings(listingIds);
      }
    }
  }, [location]);

  const {showErrorToast} = useToast();

  const handleErrorAddingToCollection = (error) => {
    console.error('Error adding to collection:', error);
    showErrorToast('Failed to add listing to collection. Please try again.');
  };

  const shouldRenderListingCards = !isFetching && !error && listings?.length > 0;

  return (
    <>
      <EmptyState
        isFetching={isFetching}
        error={error}
        hasListings={listings?.length > 0}
      />
      {shouldRenderListingCards && (
        <div data-testid="ListingCards__container" className="uc-sl-listing-cards-container">
          {listings.map((listing, idx) => (
            <ListingCardItem
              key={idx}
              listing={listing}
              agentId={agentId}
              displayCarousel={displayCarousel}
              isLoggedIn={isLoggedIn}
              onOpenSelectDefaultModal={handleOpenSelectDefaultModal}
              onLogin={handleLogin}
              onErrorAddingToCollection={handleErrorAddingToCollection}
              isInACollection={listingsInCollection[listing.listingIdSHA]}
              isInProgress={collectionsInProgress[listing.listingIdSHA]}
            />
          ))}
        </div>
      )}
    </>
  );
};

ListingCards.propTypes = {
  agentId: PropTypes.string,
  error: PropTypes.bool,
  getListings: PropTypes.func,
  isFetching: PropTypes.bool,
  listings: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object,
  displayCarousel: PropTypes.bool,
};

const mapStateToProps = (state) => {
  if (!state || !state.listings) {
    return {};
  }
  const {listings, isFetching, error} = state.listings;
  return {
    listings: Object.values(listings),
    isFetching,
    error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getListings: (listingIds) => {
    dispatch(listingsActions.getListings(listingIds));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListingCards);
