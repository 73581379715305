import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Toast} from '@uc/cx.react';
import {actionEmitter} from '../event-emitters/actionEmitter';

const StyledToast = styled(Toast)`
  z-index: var(--cx-zIndex-10);
`;

export const ToastWrapper = () => {
  const [toast, setToast] = useState(null);
  const [toastRoot, setToastRoot] = useState(null);

  useEffect(() => {
    let root = document.getElementById('toast-root');
    if (!root) {
      root = document.createElement('div');
      root.id = 'toast-root';
      document.body.appendChild(root);
    }
    setToastRoot(root);

    const showToast = (event) => {
      setToast(event);
      if (event.duration) {
        setTimeout(() => {
          setToast(null);
        }, event.duration + 200); // always give 200ms+ for Toast transition to finish
      }
    };

    actionEmitter.on(actionEmitter.constructor.Event.SHOW_TOAST, showToast);

    return () => {
      actionEmitter.off(actionEmitter.constructor.Event.SHOW_TOAST, showToast);
    };
  }, []);

  if (!toast || !toastRoot) {
    return null;
  }

  return (
    <StyledToast
      isOpen={!!toast}
      root={toastRoot}
      duration={toast.duration}
      variant={toast.variant}
      action={toast.action}
      onActionClick={toast.onActionClick}
      >
      {toast.message}
    </StyledToast>
  );
};
