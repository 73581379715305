import React, {createContext, useContext, useCallback} from 'react';
import PropTypes from 'prop-types';
import {actionEmitter, ToastVariant} from '../event-emitters/actionEmitter';

const ToastContext = createContext();

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const ToastProvider = ({children}) => {
  const showToast = useCallback((message, options = {}) => {
    actionEmitter.showToast(message, options);
  }, []);

  const showSuccessToast = useCallback((message, options = {}) => {
    showToast(message, {...options, variant: ToastVariant.SUCCESS});
  }, []);

  const showErrorToast = useCallback((message, options = {}) => {
    showToast(message, {...options, variant: ToastVariant.ERROR});
  }, []);

  const showInfoToast = useCallback((message, options = {}) => {
    showToast(message, {...options, variant: ToastVariant.INFO});
  }, []);

  const showWarningToast = useCallback((message, options = {}) => {
    showToast(message, {...options, variant: ToastVariant.WARNING});
  }, []);

  const value = {
    showToast,
    showSuccessToast,
    showErrorToast,
    showInfoToast,
    showWarningToast,
  };

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
