import {createReducer} from './utils';
import {actionTypes} from '@context/actions/listings';

export const listingsInitalState = {
  listings: {},
  isFetching: false,
  error: false,
};

const fetchListings = (state) => ({...state, isFetching: true});

const fetchListingsSuccess = (state, payload) => {
  const {listings} = payload;
  const listingsMap = listings.reduce((ls, listing) => {
    ls[listing.listingIdSHA] = listing;
    return ls;
  }, {});

  return {
    ...state,
    listings: {...state.listings, ...listingsMap},
    isFetching: false,
    error: false,
  };
};

const fetchListingsFailure = (state) => ({
  ...state,
  isFetching: false,
  error: true,
});

export const listingsReducer = createReducer({
  [actionTypes.FETCH_LISTINGS]: fetchListings,
  [actionTypes.FETCH_LISTINGS_SUCCESS]: fetchListingsSuccess,
  [actionTypes.FETCH_LISTINGS_FAILURE]: fetchListingsFailure,
});

export default listingsReducer;
