import React from 'react';
import PropTypes from 'prop-types';
import {loadCurrentModal} from '@uc/modal-loader';
import {formatListingUrl} from '@uc/format';
import ListingPhotoCard from '@uc/listing-photocard.react';
import {SOURCE_APP} from '@constants';

const ListingCardItem = ({
  listing,
  agentId,
  displayCarousel,
  isLoggedIn,
  onOpenSelectDefaultModal,
  onLogin,
  onErrorAddingToCollection,
  isInACollection,
  isInProgress,
}) => {
  const onListingClick = () => {
    window.open(
      formatListingUrl(listing, {
        agent_id: agentId,
        origin: SOURCE_APP,
        origin_type: 'Listing Photocard',
      }),
    );
  };

  const handleCollectionClick = (listing) => {
    if (!isLoggedIn) {
      const authModalOptions = {
        authTriggerEvent: 'Add to Collection Button Clicked',
        authTriggerSource: 'Listing Photocard',
        formType: 'LOGIN',
        onLoginHref: false,
      };

      return loadCurrentModal('modal--authentication', authModalOptions)
        .then((input) => {
          try {
            // Call onLogin callback
            onLogin(input);
          } catch (error) {
            onErrorAddingToCollection(error);
          }

          // Open the Add to Collection modal
          return onOpenSelectDefaultModal(listing);
        })
        .catch(() => {
          // modal was cancelled
        });
    }

    onOpenSelectDefaultModal(listing);
  };

  return (
    <ListingPhotoCard
      hideFavoriteButton={window?.uc?.user?.isAgent || false}
      data={listing}
      displayCarousel={displayCarousel}
      isLoggedIn={isLoggedIn}
      lazyLoadSingleImage
      listingAgentOverride={agentId}
      handleCustomCollectionClick={handleCollectionClick}
      onListingClick={onListingClick}
      isSubstatsPoptipEnabled
      onLogin={onLogin}
      onErrorAddingToCollection={onErrorAddingToCollection}
      isInACollection={isInACollection || isInProgress || false}
    />
  );
};

ListingCardItem.propTypes = {
  listing: PropTypes.object.isRequired,
  agentId: PropTypes.string,
  displayCarousel: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isSubstatsPoptipEnabled: PropTypes.bool,
  onOpenSelectDefaultModal: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onErrorAddingToCollection: PropTypes.func.isRequired,
  isInACollection: PropTypes.bool,
  isInProgress: PropTypes.bool,
};

ListingCardItem.defaultProps = {
  displayCarousel: false,
  isLoggedIn: false,
  isSubstatsPoptipEnabled: false,
  isInACollection: false,
  isInProgress: false,
};

export default ListingCardItem;
