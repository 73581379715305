import PropTypes from 'prop-types';
import React from 'react';
import htmlStriper from 'string-strip-html';

import {connect} from '@context';

import {getProfileUrl} from '@utils';
import {agentProfileClicked} from '@utils/analytics';

const ABOUT_CHARACTER_LIMIT = 400;

const AgentInfo = ({agent}) => {
  if (!agent) {
    return null;
  }

  const {displayName, profile} = agent;
  const agentTitle = profile.agent && profile.agent.title && profile.agent.title;

  const profileUrl = getProfileUrl(profile);
  const profilePage =
    profile.agent && profile.agent.profilePages && profile.agent.profilePages.length > 0
      ? profile.agent.profilePages[0]
      : {};

  let agentAbout = profilePage.aboutForPrint ? htmlStriper(profilePage.aboutForPrint) : '';
  if (agentAbout.length > ABOUT_CHARACTER_LIMIT) {
    agentAbout = agentAbout.substring(0, ABOUT_CHARACTER_LIMIT).trimEnd() + '...';
  }

  const visitProfile = () => {
    agentProfileClicked(agent.personId);
    window.open(profileUrl);
  };

  return (
    <div className="agent-info-container ">
      <a href={profileUrl}>
        <div className="agent-info-name">{displayName}</div>
      </a>
      <div className="agent-info-title">{agentTitle}</div>
      <div className="agent-info-about">
        {agentAbout}
        <span className="agent-info-view-more" onClick={visitProfile}>
          {agentAbout ? ' More' : 'View profile'}
        </span>
      </div>
    </div>
  );
};

AgentInfo.propTypes = {
  agent: PropTypes.object,
};

const mapStateToProps = (state) => {
  if (!state || !state.agent) {
    return {};
  }

  return {agent: state.agent.agent};
};

export default connect(mapStateToProps)(AgentInfo);
